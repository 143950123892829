@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Poppins:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: white;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: black;
  /* background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  background-attachment: fixed; */
  background-size: 100% 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

textarea {
  color: black;
  padding: 8px;
  font-size: large;
  width: 100%;
  margin-top: 16px;
  resize: none;
  border-radius: 16px;
  text-align: left;
  outline: none;
  cursor: auto;
  border: 1px solid #001233;
  box-shadow: skyblue 6px 6px;
}

button > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
  background-color: #f5f5f5;
  border-radius: 16px;
}

textarea::-webkit-scrollbar {
  width: 15px;
  border-radius: 16px;
  background-color: #f5f5f5;
}

textarea::-webkit-scrollbar-thumb {
  padding: 1px;
  border-radius: 16px;
  background-color: #001233;
  border: 1px solid white;
  /* background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4d9c41),
    to(#19911d),
    color-stop(0.6, #54de5d)
  ); */
}

h1 {
  color: skyblue;
  font-weight: 500;
}

/* Navbar */

nav {
  height: 15vh;
  width: 100%;
  max-width: 1300px;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  z-index: 100;
}

.nav-counter {
  font-size: large;
  color: yellow;
}

.personal__logo {
  display: flex;
}

.personal__logo--text {
  font-size: large;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.personal__logo--link {
  text-decoration: underline;
  color: blue;
  background-color: darkgray;
  border-radius: 3px;
  padding: 0px 3px;
}

.personal__logo--title {
  color: skyblue;
}

.info-text {
  color: yellow;
  font-size: small;
}

.personal__logo--image-container {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.personal__logo--image-mask {
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid black;
  box-shadow: skyblue 6px 6px;
}
.personal__logo--image {
  width: 100%;
  transform: scale(1.2);
}

.personal__logo--name {
  color: skyblue;
}

.nav__link--list {
  display: flex;
  justify-content: space-between;
}

.nav__link--text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  text-align: center;
}

.nav__link--text--alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  text-align: center;
  border: 3px solid black;
  animation: blink 2s;
  animation-iteration-count: infinite;
  border-radius: 1rem;
}

@keyframes blink {
  50% {
    border-color: red;
  }
}

.nav__link--anchor {
  text-decoration: none;
  padding: 6px;
  margin-right: 20px;
  transition: all 300ms ease;
  font-weight: bold;
}

.nav__link--anchor-primary {
  padding: 6px;
  border-radius: 6px;
  margin-right: 26px;
  transition: all 300ms ease;
  background-color: #f0122d;
  color: white;
  border: 1px solid #001233;
  box-shadow: #001233 6px 6px;
}

.nav__link--anchor:hover {
  color: grey;
}

.nav__link--anchor-primary:hover {
  box-shadow: #001233 0px 0px;
  opacity: 0.7;
}

.hamburger-menu {
  display: none;
  font-size: 2em;
  transition: all 300ms ease;
  cursor: pointer;
}

.hamburger-menu:hover {
  color: grey;
}

/* Home */

.home__container {
  display: flex;
  justify-content: left;
  padding-top: 32px;
}

.home__wrapper {
  overflow: visible;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  height: 80vh;
}

.home__question-answer--wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding-left: 32px;
}

.home__brain--wrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home__brain--image-mask {
  width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
}

.home__brain--image {
  width: 100%;
}

.question__wrapper {
  height: 40vh;
  width: 60%;
  display: flex;
}

.answer__wrapper {
  display: flex;
  justify-content: flex-start;
  height: 60vh;
  width: 100%;
}

.mic__wrapper {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  position: relative;
}

.mic__button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: auto;
  border-radius: 50%;
  transition: all 300ms ease;
  cursor: pointer;
}

.pause__button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: auto;
  border-radius: 50%;
  transition: all 300ms ease;
  cursor: pointer;
}

.pause__button:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 5px solid red;
  border-radius: 50%;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.mic__button:hover {
  background-color: darkgray;
}

.mic-icon {
  font-size: xx-large;
}

.question__container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.question__container > span {
  text-align: center;
  font-size: small;
}

.question-answer-buttons__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 12px 0px 12px;
}

.player--disabled {
  z-index: 1000;
  opacity: 0.5;
  pointer-events: none;
}

.player--enabled {
  z-index: 1000;
  opacity: 1;
  pointer-events: auto;
}

.button__wrapper {
  display: flex;
  color: skyblue;
  justify-content: center;
  width: 30%;
  margin: 8px;
  padding: 3px;
  border-radius: 16px;
  font-size: large;
  transition: all 300ms ease;
  cursor: pointer;
  border: 1px solid white;
}

.button__wrapper:hover {
  background-color: skyblue;
  color: black;
}

.button__wrapper--disabled {
  display: flex;
  justify-content: center;
  width: 30%;
  padding: 3px;
  margin: 8px;
  border-radius: 16px;
  font-size: large;
  transition: all 300ms ease;
  cursor: pointer;
  pointer-events: none;
  opacity: 0.4;
  border: 1px solid white;
}

.share__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.share__wrapper > .button__wrapper--disabled {
  width: 90%;
  font-size: medium;
  background-color: black;
}

.share__wrapper > .button__wrapper {
  width: 90%;
  font-size: medium;
  background-color: black;
}

.share__wrapper > .button__wrapper:hover {
  background-color: skyblue;
  color: black;
}

.loader__container {
  height: 2vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.language__region-selection {
  display: flex;
  width: 40%;
  justify-content: space-around;
  padding: 3px 0px 3px 0px;
}

.language__select--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 50%;
}

.language__select {
  width: fit-content;
  color: black;
  margin-left: 12px;
  text-align: center;
}

.answer__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.icon {
  font-size: 2em;
  transition: all 300ms ease;
  margin: 3px;
}

.whatsapp-icon {
  font-size: 1.5em;
}

.home__rate--disabled {
  color: skyblue;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.6;
  pointer-events: none;
}

.home__rate--enabled {
  color: skyblue;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  pointer-events: pointer;
}

.socials {
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 16px;
}

.socials__link {
  margin-top: 8px;
}

.socials__image {
  height: 128px;
  transition: all 300ms ease;
}

.socials__image:hover {
  transform: scale(1.1);
}

.home__visitors-count {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  justify-content: center;
}

.home__visitors-count > span {
  border: 1px solid skyblue;
  padding: 12px;
  border-radius: 1rem;
}

.social-icons__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.contact-developer {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#developed-by {
  font-size: small;
}

.contact-developer > span {
  text-align: center;
  font-size: medium;
  margin-bottom: 6px;
}

.home__visitors-count > span {
  color: skyblue;
}

.rate-count-social__wrapper {
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.rate-count-social__wrapper--mobile {
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  display: none;
}

.rate-count-social__inner-container {
  display: flex;
  width: 60%;
  justify-content: space-around;
}

/* Footer */

.footer {
  position: absolute;
  font-size: small;
  display: flex;
  width: 100%;
  bottom: 16px;
  justify-content: center;
}

.footer__link {
  color: skyblue;
}

/* FAQs */

.faqs__container {
  height: 80vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}

.faqs__accordian {
  width: 60%;
}

.faqs__accordian-container {
  background-color: #001233;
  border: 1px solid skyblue;
  border-radius: 16px;
  margin-top: 12px;
  padding: 24px;
  transition: all 300ms ease;
  cursor: pointer;
}

.faqs-text-link {
  text-decoration: underline;
  color: skyblue;
}

.faqs__accordian-container:hover {
  background-color: skyblue;
  border: 1px solid #001233;
}

.faqs__accordian-container:hover h3 {
  color: #001233;
}

.faqs__accordian-container:hover .faqs__accordian-content {
  color: #001233;
}

.faqs__accordian-container:hover .faqs__accordian-content > p > p {
  color: #001233;
}

.faqs__accordian-container:hover .faqs-text-link {
  color: blue;
}

.faqs__accordian-title {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.faqs__accordian-content {
  margin-top: 12px;
  max-height: 0px;
  overflow: hidden;
  text-align: justify;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  font-size: small;
}

.faqs__accordian-content.show {
  height: auto;
  max-height: 9999px;
  text-align: justify;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

/* Stats */

.stats__container {
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
}

.stats__header {
  display: flex;
  justify-content: center;
}

.stats__wrapper {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
}

.stats__card {
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: center;
  background-color: #001233;
  border: 1px solid white;
  padding: 12px;
  border-radius: 16px;
  margin: 24px;
  justify-content: center;
  align-items: center;
  max-height: 20vh;
  overflow: hidden;
}

.stats__card--inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  border: 1px solid white;
  border-radius: 16px;
  margin-top: 1rem;
}

.stats__card--inner-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #001233;
  background-color: #f5f5f5;
  border-radius: 16px;
}

.stats__card--inner-container::-webkit-scrollbar {
  width: 16px;
  border-radius: 16px;
  background-color: #f5f5f5;
}

.stats__card--inner-container::-webkit-scrollbar-thumb {
  padding: 1px;
  border-radius: 16px;
  background-color: #001233;
  border: 1px solid white;
}

.stats__card h3 {
  color: white;
}

.stats__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.stats__numeral {
  color: skyblue;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #000000e1;
  opacity: 0.8; */
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modal {
  font-size: larger;
  width: clamp(50%, 700px, 90%);
  height: fit-content;
  background-color: #001233;
  border: 2px solid skyblue;
  margin: auto;
  padding: 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal__header {
  display: flex;
  width: 100%;
  justify-content: right;
}

.modal > h2 {
  color: white;
  text-align: center;
  text-shadow: 1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,
    1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
}

.modal > p {
  font-size: medium;
  color: red;
}

.modal__question {
  width: 90%;
  margin-top: 16px;
  background-color: white;
  border-radius: 12px;
  padding: 3px 12px;
  cursor: pointer;
  color: #001233;
  box-shadow: skyblue 6px 6px;
  transition: all 300ms ease;
}

.modal__question:hover {
  box-shadow: skyblue 0px 0px;
  background-color: skyblue;
  color: #001233;
  transform: scale(1.03);
}

.modal__close-button {
  color: black;
  background-color: white;
  border: 1px solid skyblue;
  padding: 3px 12px;
  border-radius: 8px;
  font-size: large;
  transition: all 300ms ease;
  cursor: pointer;
  box-shadow: skyblue 6px 6px;
}

.modal__close-button:hover {
  box-shadow: skyblue 0px 0px;
  background-color: skyblue;
  color: #001233;
}

.answer__header {
  display: flex;
  justify-content: space-between;
}

.construction {
  font-size: larger;
}

/* Recently Asked Questions */

.recent-qs-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.translate-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recentqs__container {
  height: 80vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}

.recentqs__wrapper {
  border: 3px solid skyblue;
  margin-top: 12px;
  border-radius: 16px;
  height: 50vh;
  width: 60%;
  overflow-y: scroll;
  padding: 1px 16px 16px 16px;
  background-color: #001233;
}

.recentqs__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #001233;
  background-color: #f5f5f5;
  border-radius: 16px;
}

.recentqs__wrapper::-webkit-scrollbar {
  width: 16px;
  border-radius: 16px;
  background-color: #f5f5f5;
}

.recentqs__wrapper::-webkit-scrollbar-thumb {
  padding: 1px;
  border-radius: 16px;
  background-color: #001233;
  border: 1px solid white;
  /* background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4d9c41),
    to(#19911d),
    color-stop(0.6, #54de5d)
  ); */
}

.recentqs__div {
  display: flex;
  margin: 0 auto;
  width: 100%;
  margin-top: 16px;
  background-color: white;
  border: 1px solid skyblue;
  border-radius: 12px;
  padding: 3px 12px;
  cursor: pointer;
  color: #001233;
  box-shadow: skyblue 6px 6px;
  transition: all 300ms ease;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recentqs__div:hover {
  box-shadow: skyblue 0px 0px;
  background-color: skyblue;
  color: #001233;
  transform: scale(1.03);
}

.recentqs__loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;
}

.recentqs__loader--disabled {
  display: flex;
  height: 0;
  justify-content: center;
  align-items: center;
}

.hcp__image-container {
  position: absolute;
  width: 80vh;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}

.hcp__image {
  width: 100%;
}

.topic__content {
  position: absolute;
  top: 16%;
  left: 36%;
  width: 30%;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.topic__span {
  color: #001233;
  font-size: large;
  font-weight: bold;
  /* text-shadow: 2px 0 skyblue, -2px 0 skyblue, 0 2px skyblue, 0 -2px skyblue,
    1px 1px skyblue, -1px -1px skyblue, 1px -1px skyblue, -1px 1px skyblue; */
  transition: all 300ms ease;
  cursor: pointer;
  text-align: center;
  color: blue;
}

.topic__span:hover {
  transform: scale(1.2);
}

.topic__content {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.topic__content.zoom-in-out {
  opacity: 0;
  transform: scale(1.2);
}

.topic__content.zoom-in-in {
  opacity: 1;
  transform: scale(1.2);
}

.hcp__loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;
}

.hcp__loader--disabled {
  display: flex;
  height: 0;
  justify-content: center;
  align-items: center;
}

.tts {
  display: flex;
  padding-top: 5px;
  position: relative;
  justify-content: center;
}

.tts-loader {
  position: absolute;
  top: 20%;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 0px 6px;
}

.language__select--button {
  display: flex;
  color: skyblue;
  justify-content: center;
  align-items: center;
  margin: 4px 0px;
  padding: 3px 12px;
  border-radius: 16px;
  font-size: medium;
  transition: all 300ms ease;
  cursor: pointer;
  border: 1px solid white;
}

.language__select--button:hover {
  background-color: skyblue;
  color: black;
}

.language-modal__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding: 12px;
}

.language__button {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 2px 6px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 12px;
  background-color: white;
  color: #001233;
  box-shadow: skyblue 6px 6px;
  transition: all 300ms ease;
}

.language__button > p {
  color: #001233;
  text-align: center;
}

.language__button:hover {
  box-shadow: skyblue 0px 0px;
  background-color: skyblue;
  transform: scale(1.03);
}

.greet {
  text-align: center;
  padding: 12px;
  color: skyblue;
}

/* Create your own digital avatar */

.create-your-avatar--container {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-your-avatar--text-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-your-avatar--text-wrapper > h4 {
  font-weight: lighter;
  text-align: center;
  font-size: medium;
}

.anchor {
  color: skyblue;
  text-decoration: underline;
  cursor: pointer;
}

.flow-diagram-image {
  width: 60%;
}

@media (max-width: 1370px) {
  .nav__link--anchor {
    font-size: small;
  }
  .home__question-answer--wrapper {
    width: 60%;
  }
  h4.personal__logo--title {
    font-size: medium;
  }
  h1.personal__logo--name {
    font-size: large;
  }
  .hcp__image-container {
    width: 85vh;
  }
  .topic__span {
    font-size: medium;
  }
  .question__container > span {
    font-size: small;
  }
  textarea {
    font-size: medium;
  }
  .button__wrapper {
    font-size: medium;
  }
  .button__wrapper--disabled {
    font-size: medium;
  }
  .home__visitors-count > span {
    font-size: small;
  }
  .home__rate--disabled > p {
    font-size: small;
  }
  .home__rate--enabled > p {
    font-size: small;
  }
  .home__rate--disabled > span {
    font-size: 1.3em;
  }
  .home__rate--enabled > span {
    font-size: 1.3em;
  }
  .icon {
    font-size: 1.5em;
  }
  .modal-nav__link {
    text-align: center;
  }
}

@media (max-width: 1086px) {
  .nav__link--anchor {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
  .hcp__image-container {
    width: 75vh;
  }
  .topic__span {
    font-size: small;
  }
  .navModal {
    font-size: large;
    width: clamp(50%, 700px, 90%);
    height: -moz-fit-content;
    height: fit-content;
    background-color: #001233;
    border: 2px solid skyblue;
    margin: auto;
    padding: 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contact-developer > span {
    font-size: smaller;
  }
}

@media (max-width: 956px) {
  .question__container > span {
    font-size: smaller;
  }
  .hcp__image-container {
    width: 65vh;
  }
  .button__wrapper {
    font-size: small;
  }
  .button__wrapper--disabled {
    font-size: small;
  }
  .home__rate--disabled > p {
    font-size: small;
  }
  .home__rate--enabled > p {
    font-size: small;
  }
  .home__rate--disabled > span {
    font-size: 1.1em;
  }
  .home__rate--enabled > span {
    font-size: 1.1em;
  }
  .share__wrapper > .button__wrapper--disabled {
    font-size: small;
  }
  .share__wrapper > .button__wrapper {
    font-size: small;
  }
  .create-your-avatar--text-wrapper > h4 {
    font-size: small;
  }
  .contact-developer > span {
    font-size: x-small;
  }
  #developed-by {
    font-size: xx-small;
  }
}

@media (max-width: 805px) {
  .home__question-answer--wrapper {
    width: 100%;
    height: 100vh;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: flex-start;
  }
  .question-answer-buttons__wrapper {
    padding: 3px 3px 0px 3px;
  }
  .hcp__image-container {
    width: 27vh;
    z-index: 50;
  }
  .question__wrapper {
    width: 100%;
    height: 25vh;
  }
  textarea {
    font-size: x-small;
  }
  .question__container {
    justify-content: flex-start;
  }
  .topic__span {
    font-size: 6px;
  }
  .question__container > span {
    text-align: center;
    font-size: small;
  }
  .home__rate--disabled > p {
    text-align: center;
    font-size: xx-small;
  }
  .home__rate--enabled > p {
    font-size: xx-small;
  }
  .home__rate--disabled > span {
    font-size: 0.8em;
  }
  .home__rate--enabled > span {
    font-size: 1.1em;
  }
  .home__rate--disabled {
    padding: 12px;
    width: 50%;
  }
  .home__rate--enabled {
    padding: 12px;
    width: 50%;
  }
  .home__visitors-count {
    padding: 3px;
    width: 50%;
    margin-top: 12px;
  }
  .social-icons__container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px;
  }
  .home__visitors-count > span {
    font-size: x-small;
    text-align: center;
  }
  .modal {
    font-size: small;
  }
  .modal > p {
    font-size: small;
  }
  .modal__close-button {
    font-size: small;
  }
  .modal-nav__link--list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal-nav--link--anchor {
    text-align: center;
    font-size: xx-large;
    color: white;
  }
  .navModal {
    font-size: large;
    width: clamp(50%, 700px, 90%);
    height: fit-content;
    background-color: #001233;
    border: 2px solid skyblue;
    margin: auto;
    padding: 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home__question-answer--wrapper {
    height: 80vh;
    position: relative;
  }
  .question__wrapper {
    height: fit-content;
  }
  .answer__wrapper {
    height: fit-content;
  }
  .loader__container {
    height: 2vh;
  }
  .backdrop {
    z-index: 200;
  }
  .faqs__container {
    font-size: xx-small;
    height: 60vh;
  }
  .faqs__accordian-container {
    padding: 8px;
  }
  .recentqs__container {
    font-size: xx-small;
    height: 60vh;
  }
  .recentqs__div {
    box-shadow: skyblue 2px 2px;
    border-radius: 6px;
    margin-top: 6px;
    font-size: small;
  }
  .recentqs__wrapper {
    width: 90%;
    border-radius: 12px;
    padding: 1px 12px 12px 12px;
  }
  .stats__container {
    height: 60vh;
    font-size: xx-small;
    margin-top: 32px;
  }
  .stats__content {
    text-align: center;
  }
  .stats__card {
    margin: 12px;
  }
  .modal {
    font-size: xx-small;
  }
  .modal__question {
    box-shadow: skyblue 2px 2px;
    border-radius: 6px;
  }
  .modal__close-button {
    font-size: xx-small;
    box-shadow: skyblue 2px 2px;
  }
  .rate-count-social__wrapper {
    display: none;
  }
  .rate-count-social__wrapper--mobile {
    position: absolute;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    padding: 12px 0px 0px 0px;
    z-index: 60;
    align-items: center;
    width: 50%;
  }
  .rate-count-social__inner-container {
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
  }
  .modal > p {
    text-align: center;
  }
  .info-text {
    font-size: x-small;
  }
  .icon {
    font-size: 1.5em;
  }
  .button__wrapper--disabled {
    font-size: x-small;
  }
  .button__wrapper {
    font-size: x-small;
  }
  button > span {
    font-size: x-small;
  }
  .home__container {
    padding-top: 0px;
  }
  .player--disabled {
    height: 5vh;
  }
  .tts {
    width: 100%;
    padding: 12px 12px 0px 12px;
  }
  .language__select--container {
    width: 100%;
  }
  .language__select--container > p {
    font-size: x-small;
  }
  .language__select {
    font-size: x-small;
  }
  .create-your-avatar--text-wrapper {
    padding: 3px;
  }
  .create-your-avatar--text-wrapper > h4 {
    font-size: x-small;
    text-align: center;
  }
  .flow-diagram-image {
    width: 100%;
  }
  .contact-developer {
    width: 100%;
    margin-left: 6px;
    margin-bottom: 6px;
  }
  .contact-developer > span {
    font-size: x-small;
  }
  #developed-by {
    font-size: xx-small;
  }
}
